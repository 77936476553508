import CoreApi from "../CoreApi.js";
import { prepareFilter, prepareSort} from "../utils/index.js";
import { transformResponse, transformErrorResponse } from "@fifthsun/ui/utils";
import { GetSchedules, GetSchedulesVariables, GetSchedules_AliasMap, deserializeGetSchedules } from "./GetSchedules.js";

// Extends the CoreApi with /schedules endpoints
export const SchedulesApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getSchedules: builder.query<GetSchedules, GetSchedulesVariables>({
      query: (params) => ({
        url: '/schedules',
        method: "POST",
        // TO DO: modify the filter + sort on the server side so that everything can be snake_cased
        // currently, there is a mix of snake_case and camelCase expected for different things.
        body: {
          ...params,
          filter: params?.filter && prepareFilter(params.filter, GetSchedules_AliasMap),
          sort: params?.sort && prepareSort(params.sort, GetSchedules_AliasMap),
        }
      }),
      transformResponse: transformResponse(deserializeGetSchedules),
      transformErrorResponse: transformErrorResponse("Core", "getSchedules"),
    }),
  }),
});

export default SchedulesApi;
