import CoreApi from "../CoreApi.js";
import { prepareFilter, prepareSort} from "../utils/index.js";
import { transformErrorResponse, transformResponse } from "@fifthsun/ui/utils";
import { GetQuotumUsage, GetQuotumUsageVariables, GetQuotumUsage_Input_AliasMap, deserializeGetQuotumUsage } from "./GetQuotumUsage.js";

export const QuotumUsageApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuotumUsage: builder.query<GetQuotumUsage, GetQuotumUsageVariables>({
      query: (params) => ({
        url: '/quotum_usage',
        method: "POST",
        body: {
          ...params,
          filter: params?.filter && prepareFilter(params.filter, GetQuotumUsage_Input_AliasMap),
          sort: params?.sort && prepareSort(params.sort, GetQuotumUsage_Input_AliasMap),
        }
      }),
      transformResponse: transformResponse(deserializeGetQuotumUsage),
      transformErrorResponse: transformErrorResponse("Core", "getQuotumUsage"),
    }),
  }),
});

export default QuotumUsageApi;
