import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { isUndefined } from "lodash";
import "./BatchProgressCellRenderer.scss";
import { Batch } from "../../../../api/core/index.js";
import { LinearProgress } from "@mui/material";

interface BatchProgressCellRendererProps extends ICellRendererParams {
  data: Batch;
  showPrinted?: boolean;
}

export function BatchProgressCellRenderer({
  value,
  data,
  showPrinted,
}: BatchProgressCellRendererProps) {
  if (isUndefined(value)) return null;

  // Do we have stats?
  let pct = Math.ceil(value * 100.0);
  let stats: JSX.Element | JSX.Element[] = <></>;
  const lineItemStats = data?.lineItemStats;

  if (
    showPrinted &&
    lineItemStats?.progressionCount?.printed &&
    lineItemStats?.totalCount
  ) {
    pct = Math.ceil(
      (lineItemStats?.progressionCount?.printed / lineItemStats.totalCount) *
        100.0
    );
    stats = (
      <div key="stats" className="mad-batch-progress-stats mad-batch-progress-row">
        <b>
          {lineItemStats.progressionCount.printed} / {lineItemStats.totalCount}
        </b> 
        Printed
      </div>
    );
  }

  return (
    <div className="mad-batch-progress">
      {stats}
      <div key="primary" className="mad-batch-progress-row">
        <LinearProgress key="progress-bar" className="mad-batch-progress-bar" value={pct} title={`${pct}%`} variant={"determinate"} />
        <span key="progress-label" className="mad-batch-progress-label">{`${pct}%`}</span>
      </div>
    </div>
  );
}
