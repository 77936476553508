import React, { useMemo } from "react";
import { DataGrid, DataGridProps } from "@fifthsun/ui/ag-grid";
import defaultColumnDefinitions from "./defaultColumnDefinitions.js";
import { GetRowIdParams } from "ag-grid-enterprise";
import useOrdersDatasource, { OrdersDatasourceProps } from "./useOrdersDatasource.js";
import { Order } from "../../../api/core/index.js";
import { defaultColDef } from "@fifthsun/ui/ag-grid";
import { AgGridReactProps } from "ag-grid-react";

export interface OrdersDataGridProps extends DataGridProps<Order> {
}

export const OrdersDataGrid = (props?: OrdersDataGridProps) => {
  const datasourceProps = useMemo<OrdersDatasourceProps>(() => ({}), []);
  const datasource = useOrdersDatasource(datasourceProps);

  const gridProps = useMemo<AgGridReactProps<Order>>(() => {
    return {
      context: {},
      rowModelType: "serverSide",
      blockLoadDebounceMillis: 100,
      groupDisplayType: "multipleColumns",
      rowSelection: "multiple",
      enableRangeSelection: true,
      suppressRowClickSelection: true,
      defaultColDef,
      multiSortKey: 'ctrl',
      columnDefs: [...defaultColumnDefinitions],
      getRowId: (params: GetRowIdParams<Order>) => params.data.id,
      animateRows: true,
      enableCellChangeFlash: true,
      ...props
    };
  }, [props]);

  return (<DataGrid serverSideDatasource={datasource} {...gridProps} />);
};
