import React, { useMemo } from "react";
import { GetRowIdParams } from "ag-grid-enterprise";
import { DataGrid, DataGridProps } from "@fifthsun/ui/ag-grid";
import defaultColumnDefs from "./defaultColumnDefs.js";
import { GetLineItems_Include, LineItem } from "../../../api/core/index.js";
import useLineItemsDatasource from "./useLineItemDatasource.js";
import { AgGridReactProps } from "ag-grid-react";

export interface LineItemDataGridProps extends DataGridProps {
  include?: GetLineItems_Include[];
}

export const LineItemDataGrid = (props: LineItemDataGridProps) => {
  const { include, ...otherProps } = props;

  const options = useMemo<LineItemDataGridProps>(() => ({
    include
  }), [include]);

  const datasource = useLineItemsDatasource(options);

  const gridProps = useMemo<AgGridReactProps>(() => {
    return {
      columnDefs: defaultColumnDefs,
      rowModelType: 'serverSide',
      blockLoadDebounceMillis: 100,
      getRowId: (param: GetRowIdParams) => (param.data as LineItem).id,
      animateRows: true,
      enableCellChangeFlash: true,
      ...otherProps
    };
  }, [otherProps]);

  return (
    <DataGrid
      {...gridProps}
      serverSideDatasource={datasource}
    />
  );
};
