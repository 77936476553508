import { CheckboxSelectionCallbackParams, ColDef } from "ag-grid-community";

export const defaultColDef: ColDef = {
  resizable: true,
  sortable: true,
  // TO DO: enableRowGroup: true,
  checkboxSelection: (params: CheckboxSelectionCallbackParams) => {
    const displayed = params.api.getAllDisplayedColumns();
    return (displayed && displayed.length !== 0 && displayed[0] === params.column);
  },
  floatingFilter: true,
  filterParams: {
    buttons: ['clear', 'cancel', 'reset', 'apply'],
    closeOnApply: true,
    defaultToNothingSelected: true
  },
};

export default defaultColDef;
