import React, { useMemo } from "react";
import { Cascader, CascaderProps, SelectProps } from "antd";
import { LocationsApi } from "../../../api/core/index.js";

interface LocationFacilitySelectProps extends SelectProps<any> {
  value?: string[],
  onChange?: (facilityIds: string[]) => void,
  disabled?: boolean,
  useCode?: boolean,
  allowClear?: boolean,
  placeholder?: string;
}

export const LocationFacilitySelect = ({
  value,
  onChange,
  disabled,
  useCode,
  allowClear,
  placeholder
}: LocationFacilitySelectProps) => {

  const { data, isFetching: loading } = LocationsApi.useGetLocationsQuery({
    options: {
      include: ['facilities']
    }
  });

  interface Option {
    value: string | null | undefined;
    label: string | null | undefined;
    children?: Option[];
  }

  const options = useMemo<Option[]>(() => {
    return data?.rows.filter((location) => location.facilities?.length).map((location) => {
      if (location.facilities?.length === 1) {
        return {
          label: location.facilities[0].name ?? 'unknown',
          value: useCode ? location.facilities[0].code : location.facilities[0].id,
        };
      }
      return {
        label: location.name ?? 'unknown',
        value: useCode ? location.code : location.id,
        children: location.facilities?.map((facility) => ({
          label: facility.name,
          value: useCode ? facility.code : facility.id,
        }))
      };
    }).sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    }) ?? [];
  }, [data, useCode]);

  const internalOnChange: CascaderProps<Option, "value", true>['onChange'] = (_value, selectOptions) => {
    if (!onChange) {
      return;
    }

    // facilityIds = the value of all leaf nodes
    const facilityIds = selectOptions.reduce<string[]>((acc, current) => {
      if (!current.children?.length) {
        if (current.value) {
          acc.push(current.value ?? '');
        }
      } else {
        // locations can only be one level deep, so use direct child values
        current.children.forEach((child) => {
          if (child.value) {
            acc.push(child.value ?? '');
          }
        });
      }
      return acc;
    }, []);

    onChange(facilityIds?.filter((element): element is string => element !== null) ?? []);
  };

  const internalValue = useMemo<CascaderProps<Option, "value", true>["value"]>(() => {
    if (!value) {
      return [];
    }

    return options.reduce<NonNullable<CascaderProps<Option, "value", true>["value"]>>((acc, current) => {
      if (!current.children) {
        if (current.value && value.includes(current.value)) {
          acc.push([current.value]);
        }
      } else {
        // shenanigans to make TypeScript content there are no null elements
        const childValues = current.children.map((child) => child.value).filter((element): element is string => element !== null);
        // if all children are included, use just the parent value
        if (childValues.every((v) => value.includes(v))) {
          acc.push([current.value]);
        } else {
          // if some children are included, use the parent and child value
          // locations can only be one level deep, so no need to dig deeper
          value.filter((v) => childValues.includes(v)).forEach((v) => {
            acc.push([current.value!, v]);
          });
        }
      }
      return acc;
    }, []);
  }, [value, options]);

  return (
    <Cascader<Option, "value">
      placeholder={placeholder ?? "Select Facility"}
      allowClear={allowClear ?? false}
      value={internalValue}
      disabled={disabled ?? false}
      loading={loading}
      style={{ width: '100%' }}
      options={options}
      onChange={internalOnChange}
      multiple
      maxTagCount="responsive"
      maxTagPlaceholder="..."
    />
  );
};
