import { SerializableStateInvariantMiddlewareOptions, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { rtkQueryErrorLogger } from "./rtkQueryErrorLogger.js";
import CoreApi from "../api/core/CoreApi.js";

// Add additional APIs here (one per server/service generally)
const apis = [
  CoreApi
];

// We want to be able to supply complex objects likes Dates to our API endpoints.
// However, there doesn't appear to be a configuration option at this time that will let
// you serialize the arguments prior to them hitting the redux store...
// We will simply turn off the serializability check/console errors for the time being.
// While not ideal, it won't hurt any of the functionality we want to use.
const serializableCheck:SerializableStateInvariantMiddlewareOptions = {
  // ignored paths within the application state
  ignoredPaths: apis.map((_) => _.reducerPath),

  // ignore these field paths in all actions
  ignoredActionPaths: ["payload", "meta"]
};

export const store = configureStore({
  reducer: apis.reduce((result, curr) => 
    Object.assign(result, { [curr.reducerPath]: curr.reducer }), {}
  ),

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (gdm) => gdm({ serializableCheck, immutableCheck: false })
    .concat(rtkQueryErrorLogger).concat(apis.map((_) => _.middleware)),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
