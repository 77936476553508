import React, { useCallback, useState } from "react";
import { GridApi } from "ag-grid-community";
import { Modal, ModalStep, ModalStepProps } from "@fifthsun/ui";
import MainStep from "./MainStep.js";
import ModalResults from "./ModalResults.js";
import ModalState from "./ModalState.js";

export interface FloorviewSaveSortModalParams {
  open?: boolean;
  close: () => void;
  api: GridApi;
  scheduleIds: string[];
}

export const FloorviewSaveSortModal = (params: FloorviewSaveSortModalParams) => {
  const { scheduleIds, api } = params;

  // NOTE: We need to use a factory function here vs just passing in MainStep,
  // else it will confuse MainStep for the factory function!
  const [step, setStep] = useState<ModalStep<ModalResults, ModalState, 
    ModalStepProps<ModalResults, ModalState>>>(() => MainStep);

  const initState = useCallback(():ModalState => {
    return {
      scheduleIds,
      api
    } as ModalState;
  }, [scheduleIds, api]);

  return (
    <Modal<ModalResults, ModalState>
      initState={initState}
      title={(
        <>
          <span className="prefix">Save</span>
          <span className="suffix">Sort Order</span>
        </>
      )}
      open={params.open ?? false}
      close={() => params.close()}
      step={step}
      transition={(next) => setStep(next)}
    />
  );
};

export default FloorviewSaveSortModal;
