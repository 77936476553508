import React, { useEffect, useMemo, useRef } from "react";
import { GetRowIdParams, GridApi } from "ag-grid-community";
import { DataGrid, DataGridProps } from "@fifthsun/ui/ag-grid";
import orderStatsColumnDefs from "./orderStatsDefaultColumns.js";
import useOrderStatsServerSideDatasource, { useOrderStatsServerSideDatasourceProps } from "./useOrderStatsServerSideDatasource.js";
import { Store } from "../../../../api/core/Stores/index.js";
import { AgGridReactProps } from "ag-grid-react";

export interface OrderStatsDataGridProps extends DataGridProps {
  statsFrom: Date,
  statsTo: Date,
  pollInterval?: number,
}

export const OrderStatsDataGrid = (props: OrderStatsDataGridProps) => {
  const apiRef = useRef<GridApi>();

  /* eslint-disable react-hooks/exhaustive-deps */
  const datasourceProps = useMemo<useOrderStatsServerSideDatasourceProps>(() => ({
    apiRef,
    pollingInterval: props.pollInterval,
    statsFrom: props.statsFrom,
    statsTo: props.statsTo
  }), [apiRef]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const datasource = useOrderStatsServerSideDatasource(datasourceProps);

  useEffect(() => {
    datasource.setPollInterval(props.pollInterval);
  }, [datasource, props.pollInterval]);

  useEffect(() => {
    datasource.setStatsDateRange(props.statsFrom, props.statsTo);
  }, [datasource, props.statsFrom, props.statsTo]);

  const defaultGridProps = useMemo<AgGridReactProps>(() => {
    return {
      columnDefs: orderStatsColumnDefs,
      rowModelType: 'serverSide',
      blockLoadDebounceMillis: 100,
      getRowId: (param: GetRowIdParams) => (param.data as Store).id
    };
  }, []);

  return (
    <DataGrid
      apiRef={apiRef}
      plugins={props.plugins}

      {...defaultGridProps}

      serverSideDatasource={datasource}

      {...props.gridOptions}
    />
  );
};

export default OrderStatsDataGrid;
