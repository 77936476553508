import React, { useMemo } from "react";
import { GetRowIdParams, GridOptions } from "ag-grid-enterprise";
import { DataGrid, DataGridProps } from "@fifthsun/ui/ag-grid";
import { Batch, GetBatchesVariables_Includes } from "../../../api/core/index.js";
import useBatchesDatasource, { BatchesDatasourceProps } from "./useBatchDataSource.js";
import defaultColumnDefs from "./defaultColumnDefs.js";

export interface BatchDataGridProps extends DataGridProps {
  include?: GetBatchesVariables_Includes[];
}

export const BatchDataGrid = (props: BatchDataGridProps) => {
  const { include, plugins, ...gridProps } = props;

  const options: BatchesDatasourceProps = useMemo<BatchesDatasourceProps>(() => ({
    include: props.include
  }), [props.include]);

  const datasource = useBatchesDatasource({ options });

  const defaultGridOptions:GridOptions = useMemo(() => {
    return {
      columnDefs: defaultColumnDefs,
      rowModelType: 'serverSide',
      blockLoadDebounceMillis: 100,
      getRowId: (param: GetRowIdParams) => (param.data as Batch).id,
      animateRows: true,
      enableCellChangeFlash: true,
    };
  }, []);

  return (
    <DataGrid
      {...defaultGridOptions}
      serverSideDatasource={datasource}
      plugins={plugins}
      {...gridProps}
    />
  );
};

export default BatchDataGrid;
