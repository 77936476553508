import { useMemo } from "react";
import { 
  ScansApi, GetScans, AgGridDatasourceProps, GetScansVariables_Options, GetScansVariables, 
  useAgGridDatasource 
} from "../../api/core/index.js";

export interface ScansServerSideDatasourceProps 
  extends Exclude<AgGridDatasourceProps<GetScansVariables_Options, GetScansVariables, GetScans>, "endpoint"> {}

export function useScansServerSideDatasource(props?: ScansServerSideDatasourceProps) {
  const datasourceProps = useMemo<ScansServerSideDatasourceProps>(() => {
    return {
      ...props,
      endpoint: ScansApi.endpoints.getScans,
      options: {
        count: true, // ??? - very expensive
        ...props?.options
      }
    };
  }, [props]);

  return useAgGridDatasource(datasourceProps);
}

export default useScansServerSideDatasource;
