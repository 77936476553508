import React, { useMemo } from "react";
import { sortBy } from "lodash";
import { GetLineItemCountsFilterInput, GetLineItemCountsTimeColumn, LineItemsApi, StoresApi } from "../../../api/core/index.js";
import { DateTime } from "luxon";
import { DataGrid } from "@fifthsun/ui/ag-grid";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { Link } from "react-router-dom";

// const columnWidth = { store_name: 300, default: 100 };

interface LineItemDemandTableProps {
  filter?: GetLineItemCountsFilterInput;
  timeColumn?: GetLineItemCountsTimeColumn;
}

export const LineItemDemandTable = ({
  filter,
  timeColumn = GetLineItemCountsTimeColumn.ShipBy,
}: LineItemDemandTableProps) => {

  const { data: stores, isFetching: isFetchingStores } = StoresApi.useGetStoresQuery({});

  const { data: counts, isFetching: isFetchingCounts } = LineItemsApi.useGetLineItemCountsQuery({
    filter, timeColumn, groupBy: "store_id"
  });

  const sanitizedData = useMemo(() => {
    return stores && counts ? sortBy(counts, "time").map((row) => {
      const {
        time,
        value: count,
      } = row;

      const zonedTime = DateTime.fromJSDate(time, { zone: 'local' }).toFormat("MM/dd");
      const store_id = row.data.key === 'store_id' ? row.data.value : undefined;
      const store = stores.rows.find((store) => store.id === store_id);

      return {
        store_id: store_id,
        store_name: store?.name,
        /*
        [zonedTime]: {
          // TO DO: compute URL for the /line_items (with filtering) that would show the line items for this row
          // The below is what API was more or less computing as the URI. I'm leaving this incorrect value here
          // until we have time to fix it, as the report doesn't format properly if we return an undefined link
          // presently.
          link: store_id ? `/line_items?store_id=${encodeURIComponent(store_id)}` : undefined,
          value: count,
        }, */
        [zonedTime]: count,
      };
    }) : [];
  }, [counts, stores]);

  const groupedData = useMemo(() => {
    const data: any = {};
    sanitizedData.forEach((row: any) => {
      const id = row.store_name as string;
      data[id] = {
        ...(data[id] || {}),
        ...row,
      };
    });
    return data;
  }, [sanitizedData]);

  const rowData = useMemo(() => Object.values(groupedData), [groupedData]);

  const colDefs = useMemo<ColDef[]>(() => {
    const cd: ColDef[] = [
      {
        headerName: "Store ID",
        field: "store_id",
        sortable: true,
        hide: true,
      },
      {
        headerName: "Store Name",
        field: "store_name",
        sortable: true,
      }
    ];

    const registered = new Set(["store_id", "store_name"]);

    rowData.forEach((row: any) => {
      const store_id = row.store_id;
      const to = store_id ? `/line_items?store_id=${encodeURIComponent(store_id)}` : undefined;

      const keys = Object.keys(row);
      keys.forEach((key) => {
        if (!registered.has(key)) {
          registered.add(key);
          cd.push({
            field: key,
            cellRenderer: (params: ICellRendererParams) => {
              if (!params.value) { return (<div>0</div>); }
              if (store_id) { return (<Link to={to!}>{params.value}</Link>); }
              return (<div>{params.value}</div>);
            }
          } as ColDef);
        }
      });
    });

    return cd;
  }, [rowData]);

  return (
    <DataGrid
      rowModelType="clientSide"
      rowData={rowData}
      loading={isFetchingCounts || isFetchingStores}
      columnDefs={colDefs}
      // TO DO: Use (dynamic) Column Definitions for this bit:
      //defaultColumnOrder={["store_name"]}
      //columnWidth={columnWidth}
      //types={types}
      //emptyText={0}

      // TO DO: There is a new grandTotalRow property that does this, but Typescript is not recognizing it...
      // Waiting on a response from the AgGrid support as to why this is. Not vital functionality.
      //colTotals
    />
  );
};
