import React, { useEffect, useMemo, useState } from "react";
import { Skeleton, Layout } from "antd";
import { isEmpty, isUndefined } from "lodash";
import { DownCircleFilled, UpCircleFilled } from "@ant-design/icons";
import { BatchTable, Grid, Spin } from "../../index.js";
import { StatisticCard } from "../../StatisticCard/index.js";
import { ColDef } from "ag-grid-enterprise";
import { ColGroupDef, GridReadyEvent } from "ag-grid-community";
import defaultColDef from "../../Batch/Table/defaultColDef.js";
import { FloorViewLinkComponent } from "./statusBar/FloorViewLinkComponent.js";
import { DisplayedRowCountComponent, DataGridProps} from "@fifthsun/ui/ag-grid";
import { useGridStateSearchParamPlugin } from "@fifthsun/ui/ag-grid/plugins";
import FloorviewSortStatusPanel, { FloorviewSortStatusPanelParams } from "./statusBar/FloorviewSortStatusPanel.js";
import {
  BatchStatus, FacilitiesApi, Facility, FloorviewSort, FloorviewsApi, GetSchedulesFilter,
  GetSchedulesVariables_Options, SchedulesApi, Store, StoresApi
} from "../../../api/core/index.js";

const { Col, Row } = Grid;
const { Header, Content } = Layout;

function isColDef(cd: ColDef | ColGroupDef): cd is ColDef {
  return Object.prototype.hasOwnProperty.call(cd, "field");
}

export interface ScheduleDetailProps {
  activeBatchId?: string | null;
  setActiveBatchId?: (batchId: string | null | undefined) => void;
  date?: Date | null;
  facilityId?: string | null;
  onGridReady?: (e: GridReadyEvent) => void;
}

const statuses = [BatchStatus.New, BatchStatus.InProgress, BatchStatus.Pulled,
  BatchStatus.BrandingComplete, BatchStatus.DeliveredToPrinter];

export const ScheduleDetail = (props: ScheduleDetailProps) => {
  const { date, facilityId, onGridReady: innerOnGridReady } = props;

  const [headerVisible, setHeaderVisible] = useState<boolean>(false);
  const gridStateExtension = useGridStateSearchParamPlugin();
  const plugins = useMemo(() => [gridStateExtension], [gridStateExtension]);

  const [facilities, setFacilities] = useState<Facility[]>();
  const [facilitiesQueryTrigger] = FacilitiesApi.useLazyGetFacilitiesQuery();
  const facilitiesQuery = useMemo(() => {
    return facilitiesQueryTrigger({ limit: 0 }, true).unwrap();
  }, [facilitiesQueryTrigger]);

  const [stores, setStores] = useState<Store[]>();
  const [storesQueryTrigger] = StoresApi.useLazyGetStoresQuery();
  const storesQuery = useMemo(() => {
    return storesQueryTrigger({ limit: 0 }, true).unwrap();
  }, [storesQueryTrigger]);

  // NOTE: a sort exists for all schedules; if one has been explicitly assigned it will fallback
  // to the default sort
  const [scheduleId, setScheduleId] = useState<string>();
  const [savedSorts, setSavedSorts] = useState<Array<Partial<FloorviewSort>>>();
  const [sortsQueryTrigger] = FloorviewsApi.useLazyGetFloorviewSortsQuery();
  const sortsQuery = useMemo(() => {
    setScheduleId(undefined); // force the grid to be reloaded
    setSavedSorts(undefined);

    return sortsQueryTrigger({
      facilityId: props.facilityId ?? undefined,
      date: props.date ?? undefined
    }).unwrap();
  }, [sortsQueryTrigger, props.facilityId, props.date]);

  // We want the facilities to load before rendering the Grid
  useEffect(() => {
    let disposed = false;

    facilitiesQuery.then((response) => {
      if (disposed) return;
      setFacilities(response.rows);
    }, (reason) => {
      if (disposed) return;
      throw reason;
    });

    return () => { disposed = true; };
  }, [facilitiesQuery]);

  // We want the stores to load before rendering the Grid
  useEffect(() => {
    let disposed = false;

    storesQuery.then((response) => {
      if (disposed) return;
      setStores(response.rows);
    }, (reason) => {
      if (disposed) return;
      throw reason;
    });

    return () => { disposed = true; };
  }, [storesQuery, setStores]);

  // We want the sorts to load before rendering the Grid
  useEffect(() => {
    if (!props.facilityId || !props.date) {
      return;
    }

    let disposed = false;
    sortsQuery.then((response) => {
      if (disposed) { return; }
      setScheduleId(response.scheduleId);
      setSavedSorts(response.sorts);
    }, (reason) => {
      if (disposed) { return; }
      throw reason;
    });

     
    return () => { disposed = true; };
  }, [sortsQuery, setSavedSorts, props.facilityId, props.date]);

  const filter = useMemo<GetSchedulesFilter>(() => {
    const result: GetSchedulesFilter = {
      id: {
        filterType: "text",
        condition1: {
          type: "equals",
          filter: scheduleId ?? ""
        }
      }
    };

    return result;
  }, [scheduleId]);

  const options = useMemo<GetSchedulesVariables_Options>(() => ({
    include: ['schedule_stats']
  }), []);

  const { data: scheduleData } = SchedulesApi.useGetSchedulesQuery({
    limit: 0,
    filter,
    options,
  }, {
    refetchOnMountOrArgChange: true,
    skip: isUndefined(scheduleId) || isEmpty(scheduleId)
  });

  const stats = useMemo(() => scheduleData?.rows?.[0].scheduleStats, [scheduleData]);

  const gridProps = useMemo<DataGridProps>(() => ({
    blockLoadDebounceMillis: 100,
    cacheBlockSize: 25,
    statusBar: {
      statusPanels: [
        {
          statusPanel: FloorViewLinkComponent,
          statusPanelParams: {
            date,
            facilityId
          },
          align: 'left'
        },
        {
          statusPanel: FloorviewSortStatusPanel,
          statusPanelParams: {
            scheduleIds: [scheduleId]
          } as FloorviewSortStatusPanelParams,
          align: 'left',
        },
        {
          statusPanel: "agSelectedRowCountComponent",
          align: 'right'
        },
        {
          statusPanel: DisplayedRowCountComponent,
          align: 'right'
        },
      ]
    },
    animateRows: true,
    enableCellChangeFlash: true,
    onGridReady: (e: GridReadyEvent) => {
      // Override the column column definitions in order to enforce the saved sort as the
      // default sorting for this schedule
      const sortMap: Record<string, { sort: 'asc' | 'desc', sortIndex: number}> = {};
      savedSorts?.forEach((sort) => {
        sortMap[sort.field ?? ""] = {
          sort: sort.direction ?? "asc",
          sortIndex: sort.order ?? -1
        };
      });

      const noSortState = {
        sort: null,
      };

      const colDefs = e.api.getColumnDefs();
      const updatedColDefs = colDefs?.map((cd) => {
        if (!isColDef(cd)) { return cd; }

        // override the schedule.id ColDef to filter by scheduleId and make it readOnly
        if (cd.field === 'schedule.id') {
          cd = { ...defaultColDef,
            field: 'schedule.id',
            filter: "agSetColumnFilter",
            filterParams: {
              values: [scheduleId],
              defaultToNothingSelected: true,
              readOnly: true
            }
          };
        }

        return { ...cd, ...((cd.field && sortMap[cd.field]) ?? noSortState) } as ColDef<any>;
      });

      if (updatedColDefs) {
        e.api.setGridOption("columnDefs", updatedColDefs);
      }

      e.api.setFilterModel({
        status: {
          filterType: "set",
          values: statuses
        },
        'schedule.id': {
          filterType: "set",
          values: [scheduleId]
        }
      });
      e.api.onFilterChanged();
      e.api.refreshHeader();

      // invoke the event handler passed in by parent if applicable
      innerOnGridReady?.(e);
    }
  }), [date, facilityId, scheduleId, savedSorts, innerOnGridReady]);

  return (
    <Layout className="schedule-detail" style={{ display: 'flex', flexDirection: 'column', height: "100%" }}>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          height: "auto",
          width: "100%",
          backgroundColor: "white",
          borderBottom: "1px solid lightgrey",
          padding: headerVisible ? 12 : 0,
        }}
      >
        <div
          style={{
            position: "fixed",
            lineHeight: 0,
            right: 12,
            top: 12,
            cursor: "pointer",
            zIndex: 2,
          }}
          onClick={() => {
            setHeaderVisible(!headerVisible);
          }}
        >
          {headerVisible ? (
            <UpCircleFilled style={{ fontSize: "24px" }} />
          ) : (
            <DownCircleFilled style={{ fontSize: "24px" }} />
          )}
        </div>
        <div
          style={{
            visibility: headerVisible ? "visible" : "hidden",
            display: headerVisible ? "block" : "none",
          }}
        >
          <Row>
            <Col key="col_batches">
              <StatisticCard
                size="small"
                title="Batches"
                value={stats?.completedBatchesCount}
                maxValue={stats?.batchesCount}
              />
            </Col>
            <Col key="col_items">
              <StatisticCard
                size="small"
                title="Items"
                value={stats?.completedLineItemsCount}
                maxValue={stats?.lineItemsCount}
                precision={0}
              />
            </Col>
          </Row>
        </div>
      </Header>
      <Layout style={{ height: "100%", flexGrow: 1 }}>
        <Content style={{ height: "100%" }}>
          <Spin spinning={!facilities || !stores || !savedSorts} style={{ width: "100%", height: "100%" }}>
            {!(facilities && stores && savedSorts) && (
              <Skeleton active paragraph={{ rows: 8 }} />
            )}
            {facilities && stores && savedSorts && (
              <BatchTable
                {...gridProps}
                plugins={plugins}
                activeBatchId={props.activeBatchId}
                setActiveBatchId={props.setActiveBatchId}
                hideSchedule
              />
            )}
          </Spin>
        </Content>
      </Layout>
    </Layout>
  );
};
