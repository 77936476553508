import React, { useCallback, useState } from "react";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { ModalStep, ModalStepBody, ModalStepFooter, ModalStepProps } from "@fifthsun/ui";
import ModalResults from "./ModalResults.js";
import ModalState from "./ModalState.js";

import "./MainStep.scss";
import { snakeCase, startCase } from "lodash";
import { ColDef } from "ag-grid-community";
import { FloorviewsApi } from "../../../../../api/core/index.js";
import { Spin } from "../../../../Spin.js";
import * as Sentry from "@sentry/browser";

function headerName(col: ColDef) {
  if (col.field?.length) {
    return startCase(snakeCase(col.field || "").replace("_", " "));
  }
  return undefined;
}

export const MainStep:ModalStep<ModalResults, ModalState> = (props: ModalStepProps<ModalResults, ModalState>) => {
  const { api, scheduleIds } = props.state;
  const [update, { isLoading } ] = FloorviewsApi.useUpdateFloorviewSortMutation();

  const [sort] = useState(() => api
    .getColumns()?.filter((_) => _.isSorting() && _.getColDef().field)
    .sort((a, b) => a.getSortIndex()! - b.getSortIndex()!)
    .map((_) => {
      return {
        sortIndex: _.getSortIndex(),
        headerName: _.getColDef().headerName ?? headerName(_.getColDef()),
        field: _.getColDef().field!,
        direction: _.getSort()!
      };
    }) ?? []
  );

  const pinnedColumn = api.getColumns()?.find((_) => _.getColDef().field === "schedulePinned")!;
  const pinnedColDef = pinnedColumn.getColDef();
  if (sort.length === 0) {
    sort.push({
      sortIndex: 0,
      headerName: pinnedColDef.headerName ?? headerName(pinnedColDef),
      field: pinnedColDef.field!,
      direction: "desc"
    });
  } else if (sort[0].field !== pinnedColDef.field) {
    const ndx = sort.findIndex((_) => _.field === pinnedColDef.field);
    if (ndx !== -1) {
      const pinnedSort = sort[ndx];
      pinnedSort.direction = "desc";
      sort.splice(ndx, 1);
      sort.unshift(pinnedSort);
    } else {
      const pinnedSort = {
        sortIndex: 0,
        headerName: pinnedColDef.headerName ?? headerName(pinnedColDef),
        field: pinnedColDef.field!,
        direction: "desc" as "asc" | "desc" 
      };
      sort.unshift(pinnedSort);
    }

    // re-order
    sort.forEach((s, i) => { s.sortIndex = i + 1; }); // sort indeces start at 1
  } else {
    // ensure the schedulePinned column is sorted in the descending direction
    sort[0].direction = "desc";
  }

  const updateSort = useCallback(() => {
    if (!scheduleIds?.length) {
      const err = new Error("Missing Schedule Id");
      Sentry.captureException(err);
      return Promise.reject(err);
    }

    return update({
      scheduleIds,
      sort: sort.map((_) => ({ field: _.field, direction: _.direction }))
    }).unwrap().catch((reason) => {
      Sentry.captureException(reason);
      throw reason;
    });
  }, [scheduleIds, sort, update]);

  return (
    <>
      <ModalStepBody>
        <Spin tip="Saving" spinning={isLoading}>
          <p>
            Saving will modify how the batches on the Floorview for the 
            current schedule(s) are sorted for all users. Are you sure you would like to proceed?
          </p>
          <hr />
          <ol className="sort-list">
            { sort.length && sort.map((s) => (
              <li key={`${s.sortIndex}-${s.field}-${s.direction}`}>
                <span>{s.headerName ? s.headerName : s.field}</span>
                {s.direction === "asc" ? (
                  <BsSortUp className="icon icon-sort-up" />
                ) : (
                  <BsSortDown className="icon icon-sort-down" />
                )}
              </li>
            ))}
          </ol>
        </Spin>
      </ModalStepBody>
      <ModalStepFooter key="footer">
        <button type="button" onClick={() => updateSort().then((_response) => props.close())}>Save</button>
        <button type="button" onClick={props.close}>Cancel</button>
      </ModalStepFooter>
    </>
  );
};

export default MainStep;
