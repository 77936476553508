import { GridApi, IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-enterprise";
import * as Sentry from "@sentry/browser";
import { RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { StoresApi } from "../../../../api/core/Stores/index.js";

export interface useOrderStatsServerSideDatasourceProps {
  apiRef: RefObject<GridApi | null | undefined>;
  pollingInterval?: number;
  statsFrom?: Date;
  statsTo?: Date;
}

export interface IOrderStatsServerSideDatasource extends IServerSideDatasource {
  setPollInterval: (interval:number | undefined) => void;
  setStatsDateRange: (from: Date|undefined, to: Date|undefined) => void;
}

export const useOrderStatsServerSideDatasource = 
(props: useOrderStatsServerSideDatasourceProps): IOrderStatsServerSideDatasource => {
  const [getStores] = StoresApi.useLazyGetStoresQuery();
  const [interval, setInterval] = useState<number | undefined>(props.pollingInterval);
  const [statsFrom, setStatsFrom] = useState<Date | undefined>(props.statsFrom);
  const [statsTo, setStatsTo] = useState<Date | undefined>(props.statsTo);

  // polling
  useEffect(() => {
    const tmr = props.apiRef.current && interval && window.setTimeout(() => {
      props.apiRef.current?.refreshServerSide();
    }, interval);

    return () => {
      tmr && window.clearTimeout(tmr);
    };
  }, [props.apiRef, interval]);

  const setPollInterval = useCallback((pollInterval: number|undefined) => {
    setInterval(pollInterval);
    props.apiRef.current?.refreshServerSide();
  }, [props.apiRef]);

  const setStatsDateRange = useCallback((from?:Date, to?:Date) => {
    setStatsFrom(from);
    setStatsTo(to);
    props.apiRef.current?.refreshServerSide({ purge: true });
  }, [props.apiRef]);

  const datasource = useMemo<IOrderStatsServerSideDatasource>(() => ({
    setPollInterval,
    setStatsDateRange,
    getRows: (params : IServerSideGetRowsParams) => {
      const start = params.request.startRow ?? 0;
      const end = params.request.endRow ?? 0;
      const offset = start;
      const limit = (end > start) ? end - start : 0;

      const query = getStores({
        offset,
        limit,
        sort: params.request.sortModel, 
        filter: params.request.filterModel as any,
        options: {
          include: ["order_stats", "tag_list"],
          orderStats: {
            from: statsFrom,
            to: statsTo
          }
        }
      });

      query.then((response) => {
        try {
          const { count, rows } = response.data!;

          let rowCount: number | undefined;
          if ((count === undefined || count === null)) {
            if (limit > 0 && limit > rows.length) {
              rowCount = rows.length + offset;
            } else if (limit === 0) {
              rowCount = rows.length + offset;
            }
          } else {
            rowCount = count;
          }

          params.success({
            rowCount,
            rowData: rows
          });
        } catch(err) {
          Sentry.captureException(err);
          params.fail();
        }
      }, (err) => {
        Sentry.captureException(err);
  
        // if data is present, then we have at least partial results that we can display
        if (err?.data) {
          const { lineItemsV2: { count, rows } } = err.data;

          params.success({
            rowCount: (count !== null) ? count : undefined,
            rowData: rows
          });

          // TO DO: associate each error with it's row and make it accessible via the UI
        } else {
          params.fail();
        } 
      });
    }, 
    destroy: () => {
      // cleanup subscriptions - skipping this leads to rare socket error on navigation
      //subscriptions.forEach((s) => s.unsubscribe());
    }
  }), [setPollInterval, setStatsDateRange, statsFrom, statsTo, getStores]);

  return datasource;
};

export default useOrderStatsServerSideDatasource;
