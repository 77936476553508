import React, { useEffect, useMemo, useState } from "react";
import { Space, Layout, notification, Row } from "antd";
import { startCase, capitalize } from "lodash";
import { ProCard } from "@ant-design/pro-card";
import { LoadingOutlined } from "@ant-design/icons";
import { LiveIndicator, StatisticCard, Grid } from "../../index.js";
import { Text } from "../../Typography/Text/index.js";
import OrderStatsDataGrid from "./DataGrid/OrderStatsDataGrid.js";
import { DataGridProps } from "@fifthsun/ui/ag-grid";
import { IDataGridPlugin, useColumnStatePluginParams, useGridRefPlugin, useSetColumnSortPlugin, useSizeColumnsToFitPlugin } from "@fifthsun/ui/ag-grid/plugins";
import { ApplyColumnStateParams, ColumnState } from "ag-grid-community";
import { GetOrderStatsVariables, OrderStats, OrdersApi } from "../../../api/core/index.js";
import { ErrorPayload, toAbsHumanDuration } from "@fifthsun/ui/utils";
import { DateTime } from "luxon";

const { Col } = Grid;

const orderStatuses = ["active", "pending", "complete", "canceled", "invalid"];

export interface OrderDashboardProps {
  filter: GetOrderStatsVariables & { from: Date, to: Date}; // require from & to
}

const LastUpdatedAtLabel = (props: { lastUpdatedAt:Date }) => {
  const [/*timer*/, setTimer] = useState(new Date());

  // Force a re-render every 1 second
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Text small>
      <strong>Updated </strong>
      {toAbsHumanDuration(DateTime.fromJSDate(props.lastUpdatedAt), DateTime.now())}
    </Text>
  );
};

const SummaryStats = (props: OrderDashboardProps) => {
  const { filter } = props;
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  const { data, error, isFetching: loading } = OrdersApi.useGetOrderStatsQuery({
    ...filter
  }, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 5000,
  });

  useEffect(() => {
    if (data) {
      setLastUpdatedAt(new Date());
    }
  }, [data]);

  if (error) {
    const e = error as ErrorPayload;
    notification.open({
      key: "error",
      message: e.statusText ?? e.status.toString(),
      description: e.message,
      duration: 0,
    });
  }

  const statisticCards = ["orders", "lineItems"]
    .map((status) => (
      <Col key={status}>
        <StatisticCard
          title={`Total ${capitalize(startCase(status))}`}
          value={
            data ? (data as any)[`${status}Count`] : 0
          }
          series={(data?.series ?? [])}
          field={`${status}Count`}
        />
      </Col>
    )).concat(orderStatuses.map((status) => (
      <Col key={status}>
        <StatisticCard
          title={`Total ${capitalize(startCase(status))}`}
          value={data ? (data.statusCount as any)[status] : 0}
          series={(data?.series ?? [])}
          field={['statusCount', status]}
        />
      </Col>
    )));

  return (
    <ProCard.Group
      title="Stats"
      extra={[
        <div key="stats-extras">
          <Space align="center">
            <LastUpdatedAtLabel lastUpdatedAt={lastUpdatedAt} />
            {data?.lastOrderAt && (
              <Text small>
                <b>Last Order</b>:{" "}
                {toAbsHumanDuration(DateTime.fromJSDate(data.lastOrderAt), DateTime.now())}
              </Text>
            )}
            {loading ? <LoadingOutlined /> : <LiveIndicator />}
          </Space>
        </div>,
      ]}
    >
      <Row>{statisticCards}</Row>
    </ProCard.Group>
  );
};

export const OrderDashboard = ({ filter }: OrderDashboardProps) => {

  const gridRefPlugin = useGridRefPlugin();
  const sizeColumnsToFitPlugin = useSizeColumnsToFitPlugin();

  const setColumnSortPluginParams = useMemo<useColumnStatePluginParams>(() => ({
    initialState: (prev, colApi) => {
      const updatedState:ColumnState[] = [...prev];

      let ndx = prev!.findIndex((_) => colApi.getColumn(_.colId)?.getColDef().field === "name");
      updatedState[ndx] = { ...prev[ndx], sort: "asc", sortIndex: 0 };

      ndx = prev.findIndex((_) => colApi.getColumn(_.colId)?.getColDef().headerName === "#");
      updatedState[ndx] = { ...prev[ndx], hide: true };

      const applyState:ApplyColumnStateParams = {
        state: updatedState,
        applyOrder: true
      };
      return applyState;
    }
  }), []);

  const setColumnSortPlugin = useSetColumnSortPlugin(setColumnSortPluginParams);

  const gridProps = useMemo<DataGridProps<OrderStats>>(() => ({
    rowModelType: "serverSide",
    pagination: true,
    paginationPageSize: 50,
    domLayout: "autoHeight", // renders all rows to the screen instead of using virtualization,
    statusBar: undefined,
    sideBar: {
      toolPanels: undefined,
      hiddenByDefault: true
    },
    rowSelection: undefined,
    defaultColDef: {
      sortable: true,
      checkboxSelection: false,
      resizable: true,
      filter: false
    },
  }), []);

  const orderStatsDataGridPlugins = useMemo<IDataGridPlugin[]>(() => {
    return [gridRefPlugin, sizeColumnsToFitPlugin, setColumnSortPlugin];
  }, [gridRefPlugin, sizeColumnsToFitPlugin, setColumnSortPlugin]);

  const layoutStyle = useMemo<React.CSSProperties>(() =>
    ({ minHeight: '100%', minWidth: '100%', margin: '0px', padding: '0px' }), []);

  const gridContainerStyle = useMemo<React.CSSProperties>(() => ({
    height: "90vh",
    width: "100vw",
  }), []);

  return (
    <Layout style={layoutStyle}>
      <SummaryStats filter={filter} />
      <div style={gridContainerStyle}>
        <OrderStatsDataGrid
          {...gridProps}
          plugins={orderStatsDataGridPlugins}
          statsFrom={filter?.from}
          statsTo={filter?.to}
          pollInterval={5000}
        />
      </div>
    </Layout>
  );
};
