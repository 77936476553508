import CoreApi from "../CoreApi.js";
import { prepareFilter, prepareSort } from "../utils/index.js";
import { transformResponse, transformErrorResponse } from '@fifthsun/ui/utils';
import { GetLocations, GetLocationsVariables, deserializeGetLocations } from "./GetLocations.js";

// Extend the CoreApi with /locations endpoints
export const LocationsApi = CoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query<GetLocations, GetLocationsVariables>({
      query: (params) => ({
        url: '/locations',
        method: "POST",
        body: {
          ...params,
          filter: params?.filter && prepareFilter(params.filter),
          sort: params?.sort && prepareSort(params.sort),
        }
      }),
      transformErrorResponse: transformErrorResponse("Core", "getLocations"),
      transformResponse: transformResponse(deserializeGetLocations),
    }),
  }),
});

export default LocationsApi;
