import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { IFilter, IFilterParams } from "ag-grid-community";
import { AgConditionalOperator, AgDateFilter, AgDateFilterCondition, AgDateFilterConditionType } from "../../../api/core/index.js";
import { DateTime } from "luxon";
import type { RadioChangeEvent } from 'antd';
import { Radio, Select } from 'antd';

interface LocalDateFilterParams extends IFilterParams {
  timezoneField?: string;
}

const noDateTypes = ['blank', 'notBlank'];
const singleDateTypes = ['equals', 'notEqual', 'greaterThan', 'lessThan'];
const doubleDateTypes = ['inRange'];

const typeValues = [ {
  value: 'equals',
  label: 'Equals'
}, {
  value: 'notEqual',
  label: 'Not Equal'
}, {
  value: 'greaterThan',
  label: 'Greater Than'
}, {
  value: 'lessThan',
  label: 'Less Than'
}, {
  value: 'inRange',
  label: 'In Range'
}, {
  value: 'blank',
  label: 'Blank'
}, {
  value: 'notBlank',
  label: 'Not Blank'
} ];

export const LocalDateFilter = forwardRef((props: LocalDateFilterParams, ref) => {
  const isInitialRender = useRef(true);

  // fefault condition types and operator to match ag-grid built in filter behavior, rather than start off blank
  const [condition1Type, setCondition1Type] = useState<AgDateFilterConditionType | ''>("equals");
  const [condition1DateFrom, setCondition1DateFrom] = useState<string>('');
  const [condition1DateTo, setCondition1DateTo] = useState<string>('');
  const [condition2Type, setCondition2Type] = useState<AgDateFilterConditionType | ''>("equals");
  const [condition2DateFrom, setCondition2DateFrom] = useState<string>('');
  const [condition2DateTo, setCondition2DateTo] = useState<string>('');
  const [operator, setOperator] = useState<AgConditionalOperator | ''>("AND");

  // store filter model as memo so we can both return the model and check if a legitimate model exists
  const filterModel = useMemo<AgDateFilter | null>(() => {
    // see if we can construct a valid condition 1
    let condition1Filter: AgDateFilterCondition | null = null;
    if (condition1Type !== '') {
      if (doubleDateTypes.includes(condition1Type) && condition1DateFrom !== '' && condition1DateTo !== '') {
        condition1Filter = {
          type: condition1Type,
          dateFrom: condition1DateFrom,
          dateTo: condition1DateTo
        };
      } else if (singleDateTypes.includes(condition1Type) && condition1DateFrom !== '') {
        condition1Filter = {
          type: condition1Type,
          dateFrom: condition1DateFrom
        };
      } else if (noDateTypes.includes(condition1Type)) {
        condition1Filter = {
          type: condition1Type
        };
      }
    }

    // see if we can construct a valid condition 2
    let condition2Filter: AgDateFilterCondition | null = null;
    if (condition2Type !== '') {
      if (doubleDateTypes.includes(condition2Type) && condition2DateFrom !== '' && condition2DateTo !== '') {
        condition2Filter = {
          type: condition2Type,
          dateFrom: condition2DateFrom,
          dateTo: condition2DateTo
        };
      } else if (singleDateTypes.includes(condition2Type) && condition2DateFrom !== '') {
        condition2Filter = {
          type: condition2Type,
          dateFrom: condition2DateFrom
        };
      } else if (noDateTypes.includes(condition2Type)) {
        condition2Filter = {
          type: condition2Type
        };
      }
    }

    let filter: AgDateFilter | null = null;
    // see if we can construct a valid filter
    if (condition1Filter && condition2Filter && operator !== '') {
      filter = {
        filterType: 'date',
        condition1: condition1Filter,
        condition2: condition2Filter,
        operator,
        timezoneField: props.timezoneField
      };
    } else if (condition1Filter) {
      filter = {
        filterType: 'date',
        condition1: condition1Filter,
        timezoneField: props.timezoneField
      };
    }

    return filter;
  }, [condition1Type, condition1DateFrom, condition1DateTo, condition2Type, condition2DateFrom, condition2DateTo, operator, props]);

  useImperativeHandle(ref, (): IFilter => ({
    // todo: implement if we care to support front end filtering
    doesFilterPass(_params) {
      return true;
    },

    isFilterActive() {
      return filterModel !== null;
    },

    getModel(): AgDateFilter | null { 
      return filterModel;
    },

    setModel(model: AgDateFilter | null) {
      setCondition1Type(model?.condition1.type ?? '');

      if (model?.condition1.dateFrom) {
        setCondition1DateFrom(DateTime.fromISO(model?.condition1.dateFrom).toFormat('yyyy-MM-dd') ?? '');
      } else {
        setCondition1DateFrom('');
      }

      if (model?.condition1.dateTo) {
        setCondition1DateTo(DateTime.fromISO(model?.condition1.dateTo).toFormat('yyyy-MM-dd') ?? '');
      } else {
        setCondition1DateTo('');
      }

      setCondition2Type(model?.condition2?.type ?? '');

      if (model?.condition2?.dateFrom) {
        setCondition2DateFrom(DateTime.fromISO(model?.condition2?.dateFrom).toFormat('yyyy-MM-dd') ?? '');
      } else {
        setCondition2DateFrom('');
      }

      if (model?.condition2?.dateTo) {
        setCondition2DateTo(DateTime.fromISO(model?.condition2?.dateTo).toFormat('yyyy-MM-dd') ?? '');
      } else {
        setCondition2DateTo('');
      }

      setOperator(model?.operator ?? '');
    }
  }), [filterModel]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    props.filterChangedCallback?.();
  }, [filterModel, props]);

  // condition 1 type is always visible, but creating a stub so the logic is already correct if this becomes conditional in the future
  const isCondition1TypeVisible = useMemo(() => {
    return true;
  }, []);

  const isCondition1DateFromVisible = useMemo(() => {
    return isCondition1TypeVisible && (doubleDateTypes.includes(condition1Type) || singleDateTypes.includes(condition1Type));
  }, [isCondition1TypeVisible, condition1Type]);

  const isCondition1DateToVisible = useMemo(() => {
    return isCondition1TypeVisible && doubleDateTypes.includes(condition1Type);
  }, [isCondition1TypeVisible, condition1Type]);

  const isOperatorVisible = useMemo(() => {
    return isCondition1TypeVisible && (noDateTypes.includes(condition1Type) ||
    (singleDateTypes.includes(condition1Type) && condition1DateFrom !== '') ||
    (doubleDateTypes.includes(condition1Type) && condition1DateFrom !== '' && condition1DateTo !== ''));
  }, [isCondition1TypeVisible, condition1Type, condition1DateFrom, condition1DateTo]);

  const isCondition2TypeVisible = useMemo(() => {
    return isOperatorVisible;
  }, [isOperatorVisible]);

  const isCondition2DateFromVisible = useMemo(() => {
    return isCondition2TypeVisible && operator !== '' && (doubleDateTypes.includes(condition2Type) || singleDateTypes.includes(condition2Type));
  }, [isCondition2TypeVisible, operator, condition2Type]);

  const isCondition2DateToVisible = useMemo(() => {
    return isCondition2TypeVisible && operator !== '' && doubleDateTypes.includes(condition2Type);
  }, [isCondition2TypeVisible, operator, condition2Type]);

  // todo: style this to better match ag-grid
  // visibility of elements should match the default ag-grid date filter
  return (
    <>
      <div className="ag-filter-body-wrapper ag-simple-filter-body-wrapper">

        { isCondition1TypeVisible &&
          (
            <Select
              style={{ width: '100%' }}
              value={condition1Type}
              onChange={setCondition1Type}
              options={typeValues}
            />
          )
        }

        { isCondition1DateFromVisible &&
          <input type="date" value={condition1DateFrom} onChange={(e) => setCondition1DateFrom(e.target.value)} />
        }
        { isCondition1DateToVisible &&
          <input type="date" value={condition1DateTo} onChange={(e) => setCondition1DateTo(e.target.value)} />
        }

        { isOperatorVisible &&
          (
            <Radio.Group onChange={(e: RadioChangeEvent) => setOperator(e.target.value)} value={operator}>
              <Radio value="AND">AND</Radio>
              <Radio value="OR">OR</Radio>
            </Radio.Group>
          )
        }

        { isCondition2TypeVisible &&
          (
            <Select
              style={{ width: '100%' }}
              value={condition2Type}
              onChange={setCondition2Type}
              options={typeValues}
            />
          )
        }

        { isCondition2DateFromVisible &&
          <input type="date" value={condition2DateFrom} onChange={(e) => setCondition2DateFrom(e.target.value)} />
        }
        { isCondition2DateToVisible &&
          <input type="date" value={condition2DateTo} onChange={(e) => setCondition2DateTo(e.target.value)} />
        }
      </div>
    </>
  );
});

LocalDateFilter.displayName = "LocalDateFilter";
