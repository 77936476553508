import { Menu } from 'antd';
import React, { useCallback, useState, useEffect, HTMLAttributes } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { MenuClickEventHandler, MenuInfo } from 'rc-menu/lib/interface.js';
import Logo from "./Logo/index.js";
import { useMenuItems } from "./useMenuItems.js";
import { Link } from 'react-router-dom';
import { ThemeColorModeToggle } from "@fifthsun/ui/theme";
import { ItemType } from 'antd/lib/menu/interface.js';
import { Mauthra } from '@fifthsun/ui/api';
import { useFeatureFlag } from '@fifthsun/ui';
import { FF_MAUTHRA_OAUTH2_ENABLE } from '@fifthsun/ui/api/mauthra/oauth2';
import { msalClient } from '../../DeprecatedAuth/index.js';

const pathSegment = /\/[^/?]*/g;

export interface PageHeaderProps extends HTMLAttributes<HTMLDivElement> {
}

export const PageHeader = (props: PageHeaderProps) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const items = useMenuItems();

  const mauthraEnabledFF = useFeatureFlag(FF_MAUTHRA_OAUTH2_ENABLE);

  useEffect(() => {
    let key = '';
    let curr: ItemType[] = items;
    let prev: any;
    const parts = location.pathname.match(pathSegment) ?? [];

    for (let i = 0; i < parts?.length; i++) {
      const nextKey = key + parts[i];
      const ndx = curr.findIndex((item) => item?.key === nextKey);
      if (ndx === -1) { break; }
      key = nextKey;
      prev = curr[ndx];
      if (!prev.children) { break; }
      curr = prev.children;
    }
    setSelectedKeys([key]);
  }, [items, location]);

  const onMenuClick: MenuClickEventHandler = useCallback((e : MenuInfo) => {
    if (e.key === "login") {
      if (mauthraEnabledFF?.enabled) {
        Mauthra.Oauth2.Api.Instance.login(false);
      } else {
        msalClient.loginPopup();
      }
    } else if (e.key === "logout") {
      if (mauthraEnabledFF?.enabled) {
        navigate(e.key);
      } else {
        msalClient.logoutRedirect();
      }
    } else {
      navigate(e.key);
    }
  }, [mauthraEnabledFF?.enabled, navigate]);

  return (
    <div id="mad-page-header" {...props}>
      <Link id="mad-nav-menu-logo" to="/">
        <Logo />
      </Link>
      <div id="mad-nav-menu-wrapper">
        <Menu
          id="mad-nav-menu"
          mode="horizontal"
          //disabledOverflow
          items={items}
          onClick={onMenuClick}
          selectedKeys={selectedKeys}
        />
      </div>
      <ThemeColorModeToggle />
    </div>
  );
};

export default PageHeader;
