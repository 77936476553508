import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { msalClient } from "../Client/index.js";
import { useFeatureFlag } from "@fifthsun/ui";
import { FF_MAUTHRA_OAUTH2_ENABLE } from "@fifthsun/ui/api/mauthra/oauth2";

export const DeprecatedAuthProvider = ({ children }: any) => {
  const mauthraEnabledFF = useFeatureFlag(FF_MAUTHRA_OAUTH2_ENABLE);

  // if Mauthra is enabled, then we won't bother with MSAL
  return mauthraEnabledFF?.enabled 
    ? (<>{children}</>)
    : (<MsalProvider instance={msalClient}>{children}</MsalProvider>);
};
