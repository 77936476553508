import { useMemo } from "react";
import { 
  AgGridDatasourceProps, BatchesApi, GetBatches, GetBatchesVariables, 
  GetBatchesVariables_Includes, GetBatchesVariables_Options, useAgGridDatasource 
} from "../../../api/core/index.js";

export interface BatchesDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetBatchesVariables_Options, GetBatchesVariables, GetBatches>, "endpoint"> {
    include?: GetBatchesVariables_Includes[] | null | undefined;
  }

export const DefaultBatchesDatasourceProps = Object.freeze<BatchesDatasourceProps>({
  options: {
    count: true,
    include: ["stores"]
  }
});

export function useBatchesDatasource(props: BatchesDatasourceProps = DefaultBatchesDatasourceProps) {
  const datasourceProps = useMemo<AgGridDatasourceProps<GetBatchesVariables_Options>>(() => ({
    ...props,
    endpoint: BatchesApi.endpoints.getBatches,
    options: {
      ...DefaultBatchesDatasourceProps.options,
      ...props.options
    },
  }), [props]);

  return useAgGridDatasource<GetBatchesVariables_Options>(datasourceProps);
}

export default useBatchesDatasource;
