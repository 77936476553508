import React, { useCallback, useMemo, useState } from "react";
import { Modal, Button, Result } from "antd";
import { LoadingMessage, LineItemDataGrid, LineItemDataGridProps } from "../../../components/index.js";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { BatchUnderConstructionAPI } from "../../../api/BatchUnderConstruction/index.js";
import ILineItemData from "../../../api/BatchUnderConstruction/ILineItemData.js";
import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-enterprise";
import { useGridStateSearchParamPlugin } from "@fifthsun/ui/ag-grid/plugins";
import { Picksheet, PicksheetsApi } from "../../../api/core/Picksheets/index.js";
import { Authenticated } from "@fifthsun/ui";

export const LineItemManagePage = () => {
  const [picksheetModalVisible, setPicksheetModalVisible] = useState<boolean>(false);
  const [addToBatchModalVisible, setAddToBatchModalVisible] = useState<boolean>(false);
  const [addToBatchLoading, setAddToBatchLoading] = useState<boolean>(false);
  const [addToBatchError, setAddToBatchError] = useState<string>("");

  const gridStatePlugin = useGridStateSearchParamPlugin();
  const plugins = useMemo(() => [gridStatePlugin], [gridStatePlugin]);

  const [
    createPicksheetMutation,
    {
      error: createPicksheetError,
      /* eslint-disable @typescript-eslint/no-unused-vars */
      data: createPicksheetData,
      /* eslint-enable @typescript-eslint/no-unused-vars */
      isLoading: picksheetLoading,
    },
  ] = PicksheetsApi.useCreatePicksheetMutation();

  const createPicksheet = useCallback((lineItemIds:string[]) => {
    createPicksheetMutation({ lineItemIds });
    setPicksheetModalVisible(true);
  }, [createPicksheetMutation, setPicksheetModalVisible]);

  const picksheetStatusMessage = useMemo(() => {
    if (picksheetLoading) return LoadingMessage.random();
    return createPicksheetError
      ? "Picksheet creation failed."
      : "Picksheet ready!";
  }, [createPicksheetError, picksheetLoading]);

  const hidePicksheetModal = useCallback(() => setPicksheetModalVisible(false), [setPicksheetModalVisible]);

  const addLineItemsToCurrentBatch = useCallback((lineItems:ILineItemData[]) => {
    setAddToBatchError("");
    setAddToBatchLoading(true);
    setAddToBatchModalVisible(true);

    try {
      BatchUnderConstructionAPI.add(lineItems);
    } catch(error: any) {
      setAddToBatchError(error.message);
    }

    setAddToBatchLoading(false);
  }, [setAddToBatchLoading, setAddToBatchModalVisible, setAddToBatchError]);

  const addToBatchStatusMessage = useMemo<string>(() => {
    if (addToBatchLoading) return LoadingMessage.random();
    return addToBatchError || "Line Items added to Batch!";
  }, [addToBatchError, addToBatchLoading]);

  const hideAddToBatchModal = useCallback(() => setAddToBatchModalVisible(false), [setAddToBatchModalVisible]);

  const gridProps = useMemo<Partial<LineItemDataGridProps>>(() => ({
    getContextMenuItems: (params:GetContextMenuItemsParams):Array<string|MenuItemDef> => {
      const selectedCount = params.api.getSelectedNodes().length;
      
      return [
        ...params.defaultItems!,
        {
          name: 'Create Picksheet For Selected LineItems',
          disabled: !selectedCount,
          action: () => createPicksheet(params.api.getSelectedNodes().map((_) => _.id) as string[])
        },
        {
          name: 'Add To Batch Preview',
          disabled: !selectedCount,
          action: () => addLineItemsToCurrentBatch(
            params.api.getSelectedNodes().map((_) => ({
              ziftId: _.data.ziftId,
              orderId: _.data.orderId,
              shipByDate: _.data.shipBy
            }))
          )
        }
      ];
    },
    animateRows: true,
    enableCellChangeFlash: true,
  }), [createPicksheet, addLineItemsToCurrentBatch]);

  return (
    <Authenticated>
      <LineItemDataGrid
        {...gridProps}
        plugins={plugins}
      />

      <PicksheetModal
        open={picksheetModalVisible}
        loading={picksheetLoading}
        error={!!createPicksheetError}
        statusMessage={picksheetStatusMessage}
        hide={hidePicksheetModal}
      />

      <AddToBatchModal
        open={addToBatchModalVisible}
        loading={addToBatchLoading}
        error={!!addToBatchError}
        statusMessage={addToBatchStatusMessage}
        hide={hideAddToBatchModal}
      />

    </Authenticated>
  );
};

interface PicksheetModalProps {
  open: boolean;
  loading: boolean;
  error: boolean;
  statusMessage: string;
  data?: Picksheet;
  hide: () => void;
}

const PicksheetModal = (props: PicksheetModalProps) => {
  const { open, loading, error, statusMessage, data, hide } = props;

  return (
    <Modal
      open={open}
      closable
      centered
      footer={null}
      style={{ textAlign: "center" }}
      onCancel={hide}
    >
      <Result
        status={error ? "warning" : "success"}
        icon={loading ? <LoadingOutlined /> : null}
        title={statusMessage}
      >
        {!loading && !error ? (
          <Button
            loading={loading}
            disabled={loading}
            target="_blank"
            href={data?.pdfUrl}
            type="primary"
            icon={<DownloadOutlined />}
            size="large"
          >
            Download
          </Button>
        ) : null}
      </Result>
    </Modal>
  );
};

interface AddToBatchModalProps {
  open: boolean;
  loading: boolean;
  error: boolean;
  statusMessage: string;
  hide: () => void;
}

const AddToBatchModal = (props: AddToBatchModalProps) => {
  const { open, loading, error, statusMessage, hide } = props;

  return (
    <Modal
      open={open}
      closable
      centered
      footer={null}
      style={{ textAlign: "center" }}
      onCancel={hide}
    >
      <Result
        status={error ? "warning" : "success"}
        icon={loading ? <LoadingOutlined /> : null}
        title={statusMessage}
      />
    </Modal>
  );
};
