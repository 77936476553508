import { ColDef, ColGroupDef, ICellRendererParams } from "ag-grid-community";
import { DateTimeCellRenderer, ProgressCellRenderer, RowNumberCellRenderer } from "@fifthsun/ui/ag-grid/renderers";
import { LocaleNumberCellRenderer } from "@fifthsun/ui/ag-grid/renderers";
import { Order, OrderStatus } from "../../../api/core/index.js";

// TO DO: use type ColDef<Order>
export const defaultColumnDefinitions: ReadonlyArray<ColDef<any> | ColGroupDef<any>> = [
  {
    headerName: "#",
    sortable: false,
    filter: false,
    floatingFilter: false,
    cellRenderer: RowNumberCellRenderer,
    width: 75,
    pinned: 'left'
  },
  {
    field: 'id',
    hide: true,
    filter: 'agTextColumnFilter'
  },
  { 
    field: 'ziftId',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'erpId',
    filter: 'agTextColumnFilter'
  },
  {
    field: 'status',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: Object.values(OrderStatus).map((v) => v.toLocaleLowerCase()),
    },
  },
  {
    field: 'progress',
    filter: 'agNumberColumnFilter',
    cellRenderer: ProgressCellRenderer,
  },
  {
    field: 'shipBy',
    filter: 'agDateColumnFilter',
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: 'lineItemsCount',
    filter: 'agNumberColumnFilter',
    cellRenderer: LocaleNumberCellRenderer,
  },
  {
    field: 'store.name',
    filter: 'agTextColumnFilter'
  },
  {
    field: 'facility.name',
    filter: 'agTextColumnFilter'
  },
  {
    field: 'shippingService',
    filter: 'agTextColumnFilter'
  },
  {
    field: 'shippingCarrier',
    filter: 'agTextColumnFilter'
  },
  {
    field: 'boxes',
  },
  {
    field: 'createdAt',
    filter: 'agDateColumnFilter',
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: 'updatedAt',
    filter: 'agDateColumnFilter',
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: 'auditedAt',
    filter: 'agDateColumnFilter',
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: 'completedAt',
    filter: 'agDateColumnFilter',
    cellRenderer: DateTimeCellRenderer
  },
  {
    field: 'meta',
    sortable: false,
    cellRenderer: (params: ICellRendererParams<Order>) => {
      return JSON.stringify(params.data);
    }
  },
];

export default defaultColumnDefinitions;
