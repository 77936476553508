import { useMemo } from "react";
import { OrdersApi, GetOrders, GetOrdersVariables, useAgGridDatasource, AgGridDatasourceProps, GetOrdersVariables_Options } from "../../../api/core/index.js";

export interface OrdersDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetOrdersVariables_Options, GetOrdersVariables, GetOrders>, "endpoint"> {}

export function useOrdersDatasource(props: OrdersDatasourceProps) {
  const datasourceProps = useMemo<AgGridDatasourceProps<GetOrdersVariables_Options>>(() => ({
    ...props,
    endpoint: OrdersApi.endpoints.getOrders,
    options: {
      count: true,
      include: ['facility', 'store'],
      ...props.options
    },
  }), [props]);

  return useAgGridDatasource<GetOrdersVariables_Options>(datasourceProps);
}

export default useOrdersDatasource;
