import React, { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { sum, sumBy, uniq } from "lodash";
import QuotaProgress, { QuotaRef } from "../QuotaProgress/index.js";
import { Link } from "react-router-dom";
import { Progress as AntProgress } from "antd";
import { Batch, FacilitiesApi, Schedule, StoresApi } from "../../api/core/index.js";

export interface ScheduleInfoCardRef {
  refetch: () => void;
}

interface ScheduleInfoCardProps {
  date?: Date;
  facilityId?: string,
  newSelected: ReadonlyArray<Batch>,
  schedule?: Readonly<Schedule>
}

// Not sure why eslint is complaining about propTypes here when none have been defined...
/* eslint-disable react/prop-types */
export const ScheduleInfoCard = forwardRef<ScheduleInfoCardRef, ScheduleInfoCardProps>((props, ref) => {
  const { facilityId, date, newSelected, schedule } = props;

  const { data: facilities } = FacilitiesApi.useGetFacilitiesQuery({ limit: 0 });
  const { data: stores } = StoresApi.useGetStoresQuery({ limit: 0 });

  const refs = useRef<Array<QuotaRef | null>>([]);
  useImperativeHandle(ref, () => ({
    refetch() {
      refs.current?.forEach(((quotaRef) => {
        quotaRef?.refetch();
      }));
    }
  }));

  const dateStr = useMemo(() => {
    return date ? date.toISOString().split("T")[0] : '';
  }, [date]);

  const itemsScheduled = useMemo(() => {
    return sum(props.newSelected.map((batch) => batch.printsCount)) + 
    ((props.schedule?.scheduleStats?.printsCount ?? 0) - (props.schedule?.scheduleStats?.completedPrintsCount ?? 0));
  }, [props.newSelected, props.schedule?.scheduleStats?.printsCount, props.schedule?.scheduleStats?.completedPrintsCount]);

  const facility = useMemo(() => {
    return facilities?.rows.find((f) => facilityId?.localeCompare(f.id) === 0);
  }, [
    facilityId, facilities
  ]);

  const capacity = useMemo(() => {
    const projectedDailyCapacity = facility?.capacity ?? 0;
    return projectedDailyCapacity > 0 ? Math.floor(projectedDailyCapacity) : 0;
  }, [facility?.capacity]);

  const capacityPercentage = useMemo(() => {
    return capacity === 0 ? 0 : (itemsScheduled / capacity) * 100;
  }, [itemsScheduled, capacity]);

  const storeIds = useMemo(() => {
    // get all store ids from schedule and newly selected
    const ids = uniq([
      schedule?.stores?.map((s) => s.id) ?? [],
      newSelected.flatMap((selected) => selected.storeStats)
        .filter((store) => store).map((store) => store!.storeId)
    ].flat(10));

    // get and associate name of every store
    const idNamePairs = ids.map((id) => {
      const store = stores?.rows.find((s) => {
        return id?.localeCompare(s.id) === 0;
      });
      if (store) {
        return { name: store.name, id: store.id };
      }
      return { id, name: '' };
    });

    // sort by store name
    return idNamePairs.sort((a, b) => {
      if (!a.name && !b.name) { return 0; }
      if (!a.name) { return -1; }
      if (!b.name) { return 1; }
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    }).map((p) => p.id);

  }, [schedule, newSelected, stores]);

  const storeQuotas = storeIds.map((storeId, index) => {
    const add = sumBy(props.newSelected, (batch) => {
      const storeStats = batch?.storeStats?.find(
        (stats) => stats.storeId === storeId
      );
      if (!storeStats) { return 0; }
      return (
        storeStats.totalPrints - storeStats.progressionCount.printed
      );
    });

    return (
      <QuotaProgress
        ref={(element) => { refs.current[index] = element; }} 
        key={storeId}
        variables={{
          parentId: storeId,
          parentType: "Store",
          type: "items",
          date
        }}
        add={add}
      />
    );
  });

  return (
    <div>
      <span>
        <b>
          {schedule?.id !== undefined ? (
            <Link to={`/schedules/${schedule?.id}`}>
              {dateStr}
            </Link>
          ) : (
            dateStr
          )}
        </b>
        : {itemsScheduled} / {capacity}
      </span>
      <AntProgress
        strokeColor={{
          "0%": "#108ee9",
          "100%": "#87d068",
        }}
        format={() => `${capacityPercentage.toFixed(1)}%`}
        percent={capacityPercentage}
      />
      {storeQuotas}
    </div>
  );
});
/* eslint-enable react/prop-types */

ScheduleInfoCard.displayName = "ScheduleInfoCard";

export default ScheduleInfoCard;
