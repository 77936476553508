import { GridApi, IServerSideGetRowsParams } from "ag-grid-enterprise";
import * as Sentry from "@sentry/browser";
import { BatchesApi, GetBatchesVariables_Includes } from "../../../../api/core/Batches/index.js";
import { AgGridServerSideDatasource } from "../../../../api/core/index.js";
import { useCallback, useMemo, useRef } from "react";
import { FilterChangedEvent } from "ag-grid-community";

export interface BatchServerSideDatasourceProps {
  includes?: GetBatchesVariables_Includes[];
  count?: boolean; // if true, then the datasource will fetch the count each time the filters change
}

// NOTE: Don't include any of the stats in the default includes; they are expensive
export const DefaultIncludes:GetBatchesVariables_Includes[] = [
  "auto_facility", "note", "schedule", "schedule_facility", "stores", "line_item_stats", "store_stats"
];

export const useBatchServerSideDatasource = (props: BatchServerSideDatasourceProps): AgGridServerSideDatasource => {
  const {
    includes = DefaultIncludes,
    count: canCount = false
  } = props;

  const endpoint = BatchesApi.endpoints.getBatches;
  const [trigger] = endpoint.useLazyQuery();
  const apiRef = useRef<GridApi>();
  const recomputeCount = useRef<boolean>(true);

  const onFilterChanged = useCallback((_e: FilterChangedEvent) => {
    recomputeCount.current = true;
  }, []);

  const datasource = useMemo<AgGridServerSideDatasource>(() => {
    return {
      getRows(params : IServerSideGetRowsParams) {
        if (apiRef.current !== params.api) {
          if (apiRef.current && !apiRef.current.isDestroyed()) {
            apiRef.current.removeEventListener('filterChanged', onFilterChanged);
          }
          recomputeCount.current = true;
          apiRef.current = params.api;
          apiRef.current.addEventListener('filterChanged', onFilterChanged);
        }

        const start = params.request.startRow ?? 0;
        const end = params.request.endRow ?? 0;
        const offset = start;
        const limit = (end > start) ? end - start : 0;

        // This is needed to cleanly implement the stores column def,
        // which has a complex data type. A little hacky - will need to contact support to see if
        // there isn't a better way to go about this.
        const filter = params.request.filterModel ?? {};
        if ("stores" in filter && filter.stores?.values) {
          const values = filter.stores.values.map((entry:string) => {
            const v = JSON.parse(entry);
            return v.id;
          });
          filter.stores.values = values;
          filter["stores.id"] = filter.stores;
          delete filter.stores;
        }

        const query = trigger({
          offset,
          limit,
          sort: params.request.sortModel,
          filter: filter as any,
          options: {
            include: includes,
            count: canCount && recomputeCount.current
          }
        }, false).unwrap();

        query.then((response) => {
          try {
            const { count = undefined, rows = [] } = response;

            let rowCount = count ?? undefined;
            if (rowCount === undefined) {
              if (limit > 0 && limit > rows.length) {
                rowCount = rows.length + offset;
              } else if (limit === 0) {
                rowCount = rows.length + offset;
              }
            } else {
              recomputeCount.current = false;
            }

            params.success({
              rowCount,
              rowData: rows
            });
          } catch(err: any) {
            //console.error(err);
            Sentry.captureException(err);
            params.fail();
          }
        }, (err) => {
          //console.error(err);
          Sentry.captureException(err);
          params.fail();
        });
      }, // getRows

      destroy: () => { /* cleanup here */ }
    };
  }, [includes, canCount, trigger, onFilterChanged]);

  return datasource;
};

export default useBatchServerSideDatasource;
