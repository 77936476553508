import { GridApi, IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-enterprise";
import { AgGridQueryArgs, AgGridQueryArgs_Options, AgQueryResponse } from "../AgGrid.js";
import { useCallback, useMemo, useRef } from "react";
import * as Sentry from "@sentry/browser";
import { FilterChangedEvent } from "ag-grid-community";
import { ApiEndpointQuery, QueryArgFrom, QueryDefinition, QueryStatus, TypedUseLazyQuery } from "@reduxjs/toolkit/query/react";

// TO DO: This interface got hidden... Waiting for feedback on how to import it properly
// See https://github.com/reduxjs/redux-toolkit/issues/4390
//import { QueryHooks } from "@reduxjs/toolkit/query/react/buildHooks";

// work-around: import via direct path into node_modules
//import { QueryHooks } from "../../../../node_modules/@reduxjs/toolkit/dist/query/react/buildHooks.js";

export interface AgGridDatasourceProps<
  OPTS extends AgGridQueryArgs_Options = AgGridQueryArgs_Options,
  QA extends AgGridQueryArgs<OPTS> = AgGridQueryArgs<OPTS>, 
  RT extends AgQueryResponse = AgQueryResponse, 
  QD extends QueryDefinition<QA, any, any, RT, any> = QueryDefinition<QA, any, any, RT, any>
  > {
    endpoint: ApiEndpointQuery<QD, any> & { useLazyQuery: TypedUseLazyQuery<RT, QA, any> };

    // a list of includes that will be used when issuing requests
    options?: Omit<OPTS, 'countOnly'>;

    // augment the AgGridQueryArgs generated by the useAgGridDatasource before they are issued to the endpoint
    // if QA !== AgGridQueryArgs, then you will need to supply this function in order to populate any additional fields.
    // Also useful if any special pre-processing is required.
    queryArgs?: (orig: AgGridQueryArgs) => QA,
  }

export interface GetRowCountParams {
  filterModel: any;
}

export interface AgGridServerSideDatasource extends IServerSideDatasource {
  // TO DO once we figure out how to set the row count asynchronously from getRows
  //getRowCount: (params: GetRowCountParams) => Promise<number>;
}

export function useAgGridDatasource<
  OPTS extends AgGridQueryArgs_Options = AgGridQueryArgs_Options,
  QA extends AgGridQueryArgs<OPTS> = AgGridQueryArgs<OPTS>,
  RT extends AgQueryResponse = AgQueryResponse,
  QD extends QueryDefinition<QA, any, any, RT, any> = QueryDefinition<QA, any, any, RT, any>, 
  P extends AgGridDatasourceProps<OPTS, QA, RT, QD> = AgGridDatasourceProps<OPTS, QA, RT, QD>
  >(props: P): AgGridServerSideDatasource {

  const { endpoint, options, queryArgs } = props;

  const [trigger] = endpoint.useLazyQuery();

  const apiRef = useRef<GridApi>();
  const recomputeCount = useRef<boolean>(true);

  const onFilterChanged = useCallback((_e: FilterChangedEvent) => {
    recomputeCount.current = true;
  }, []);

  const datasource = useMemo<AgGridServerSideDatasource>(() => {
    return {
      getRows(params: IServerSideGetRowsParams) {
        if (apiRef.current !== params.api) {
          if (apiRef.current && !apiRef.current.isDestroyed()) {
            apiRef.current.removeEventListener('filterChanged', onFilterChanged);
          }
          apiRef.current = params.api;
          recomputeCount.current = true;
          params.api.addEventListener('filterChanged', onFilterChanged);
        }

        const start = params.request.startRow ?? 0;
        const end = params.request.endRow ?? 0;
        const offset = start;
        const limit = (end > start) ? end - start : 0;

        const args: AgGridQueryArgs = {
          offset,
          limit,
          filter: params.request.filterModel,
          sort: params.request.sortModel,
          options: {
            ...options,

            // TO DO: I would like to make retrieving the count asynchronous from getRows()
            // However, there does not appear to be an api for SSRM that will allow me to asynchronously
            // assign the rowCount (The Viewport Row Model does support this). I am doing further
            // research and contacting support for how I might go about this. In the meantime, I will
            // at least restrict how often we fetch the count synchronously to only those times when
            // it is strictly necessary.
            count: options?.count && recomputeCount.current
          }
        };

        // TO DO: should we cancel previous requests? Perhaps when the filter/sort changes?
        const qa = (queryArgs?.(args) ?? args) as QueryArgFrom<QD>;
        const query = trigger(qa, false);//.unwrap();

        query.then((response) => {
          try {
            const { status, error } = response;
            if (status === QueryStatus.rejected) {
              if (error) {
                console.error(error);
                Sentry.captureException(error);
              }
              params.fail();
              return;
            }

            const { 
              count = undefined, 
              rows = [] 
            } = response.data!;

            let rowCount = count ?? undefined;
            if (rowCount === undefined) {
              if (limit > 0 && limit > rows.length) {
                rowCount = rows.length + offset;
              } else if (limit === 0) {
                rowCount = rows.length + offset;
              }
            } 
            
            if (rowCount !== undefined && rowCount >= 0) {
              recomputeCount.current = false;
            }

            // if this request was an export request, recalculate size on the next request
            if (limit === Number.MAX_SAFE_INTEGER)
            {
              recomputeCount.current = true;
            }

            params.success({
              rowCount,
              rowData: rows
            });
          } catch(err: any) {
            console.error(err);
            Sentry.captureException(err);
            params.fail();
          }
        }, (err: any) => {
          console.error(err);
          Sentry.captureException(err);
          params.fail();
        });
      },

      destroy: () => {
        if (apiRef.current && !apiRef.current.isDestroyed()) {
          apiRef.current.removeEventListener('filterChanged', onFilterChanged);
        }
      }
    };
  }, [options, queryArgs, onFilterChanged, trigger]);

  return datasource;
}

export default useAgGridDatasource;
