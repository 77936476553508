 
import React, { useMemo } from "react";
import { DataGrid, DataGridProps } from "@fifthsun/ui/ag-grid";
import { Button, Drawer } from "antd";
import { BatchDetail } from "../index.js";
import { TagsCellRenderer } from "@fifthsun/ui/ag-grid/renderers";
import { BatchProgressCellRenderer, StoresCellRenderer } from "./renderers/index.js";
import useBatchServerSideDatasource, { BatchServerSideDatasourceProps } from "./hooks/useBatchServerSideDatasource.js";
import * as renderers from "@fifthsun/ui/ag-grid/renderers";
import createBatchTableColDefs from "./createBatchTableColDefs.js";
import { ColDef, GetRowIdParams, ICellRendererParams, SideBarDef } from "ag-grid-enterprise";
import { BatchSchedulingToolPanel } from "./components/toolPanels/BatchSchedulingToolPanel.js";
import defaultColDef from "./defaultColDef.js";
import { BulkEditNotesToolPanel } from "./components/toolPanels/BulkEditNotesToolPanel.js";
import { Batch } from "../../../api/core/Batches/index.js";
import { FacilitiesApi, GetFacilities, GetStores, StoresApi } from "../../../api/core/index.js";
import { ErrorBoundary } from "@sentry/react";
import ErrorBoundaryFallback from "../../Page/ErrorBoundaryFallback.js";
import { useAuthentication } from "@fifthsun/ui";
import { AgGridReact } from "ag-grid-react";

import "./index.scss";

export interface BatchTableProps extends DataGridProps {
  activeBatchId?: string | null;
  setActiveBatchId?: (batchId: string | null | undefined) => void;
  hideSchedule?: boolean;
  hideUnschedule?: boolean;
}

export interface BatchTableAgGridProps extends BatchTableProps {
  facilities: GetFacilities;
  stores: GetStores;
}

const BatchTableAgGrid = (props: BatchTableAgGridProps) => {
  const { 
    apiRef, plugins, hideSchedule, hideUnschedule, setActiveBatchId, 
    activeBatchId, facilities, stores, sideBar, ...otherProps 
  } = props;  

  const datasourceProps = useMemo<BatchServerSideDatasourceProps>(() => ({ count: true }), []);
  const datasource = useBatchServerSideDatasource(datasourceProps);

  const colDefs = useMemo(() => {
    const cols = createBatchTableColDefs({
      facilities,
      stores, 
      // if we are hiding schedule, we are on the scheduler and want to sort by compound priority
      includeCompoundPriorityInSort: hideSchedule ?? false,
      // additionally, if we are on the scheduler then we want to show and default sort by the schedulePinned field
      includeSchedulePinnedField: hideSchedule ?? false
    });

    const actionsColDef: ColDef = {
      field: undefined,
      headerName: "Actions",
      sortable: false,
      cellRenderer: ActionsColumnCellRenderer(setActiveBatchId),
    };

    cols.push(actionsColDef); 
    return cols;
  }, [facilities, stores, setActiveBatchId, hideSchedule]);

  const memoizedSideBar = useMemo<SideBarDef | string[] | string | boolean | null | undefined>(() => {
    return sideBar ?? {
      toolPanels: [
        {
          id: 'columns',
          toolPanel: 'agColumnsToolPanel',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanelParams: {
            suppressPivotMode: true,
            suppressValues: true
          },
          width: 320
        }, 
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressExpandAll: true,
            suppressFilterSearch: true,
          },
          width: 320
        }, 
        {
          id: 'batchScheduling',
          labelDefault: 'Scheduling',
          labelKey: 'batchScheduling',
          iconKey: 'columns',
          toolPanel: BatchSchedulingToolPanel,
          toolPanelParams: {
            hideSchedule,
            hideUnschedule
          },
          width: 480
        },
        {
          id: 'bulkEditNotes',
          labelDefault: 'Edit Notes',
          labelKey: 'bulkEditNotes',
          iconKey: 'paste',
          toolPanel: BulkEditNotesToolPanel,
          width: 320
        }
      ] 
    };
  }, [sideBar, hideSchedule, hideUnschedule]);

  const gridProps = useMemo<DataGridProps<Batch>>(() => {
    const go: DataGridProps<Batch> = {
      context: {},
      rowModelType: "serverSide",
      blockLoadDebounceMillis: 100,
      cacheBlockSize: 25,
      groupDisplayType: "multipleColumns",
      rowSelection: "multiple",
      enableRangeSelection: true,
      suppressRowClickSelection: true,
      defaultColDef,
      multiSortKey: 'ctrl',
      getRowId: (params: GetRowIdParams<Batch>) => params.data.id,
      components: {
        ...Object.values(renderers),
        BatchProgressCellRenderer,
        StoresCellRenderer,
        TagsCellRenderer,
      },
      animateRows: true,
      enableCellChangeFlash: true,
      ...otherProps
    };

    if (apiRef) {
      go.apiRef = apiRef;
    }

    return go;
  }, [apiRef, otherProps]);

  return (
    <DataGrid<Batch>
      {...gridProps} 
      columnDefs={colDefs}
      serverSideDatasource={datasource}
      sideBar={memoizedSideBar}
      plugins={plugins} 
    />
  );
};

const BatchDetails = (props: BatchTableProps) => {
  const { activeBatchId, setActiveBatchId } = props;
  return (
    <Drawer
      open={!!activeBatchId}
      width="60vw"
      onClose={() => setActiveBatchId?.(undefined)}
    >
      {activeBatchId ? <BatchDetail id={activeBatchId} /> : undefined}
    </Drawer>
  );
};

export interface LoadErrorProps {
  message: string;
  retry: () => void;
}

export const LoadError = (props: LoadErrorProps) => {
  return (
    <div>
      <b>{props.message}</b>
      <br />
      <Button onClick={props.retry}>Retry</Button>
    </div>
  );
};

export const BatchTable = (props : BatchTableProps) => {
  const { authenticated } = useAuthentication();
  const { 
    data: facilityData, 
    refetch: facilityRefetch, 
    isSuccess: facilityIsSuccess,
    isError: facilityIsError, 
    isLoading: facilityIsLoading, 
    isUninitialized: facilityIsUninitialized 
  } = FacilitiesApi.useGetFacilitiesQuery({ limit: 0 }, { skip: !authenticated });

  const { 
    data: storeData, 
    refetch: storeRefetch, 
    isSuccess: storeIsSuccess,
    isError: storeIsError,
    isLoading: storeIsLoading, 
    isUninitialized: storeIsUninitialized
  } = StoresApi.useGetStoresQuery({ limit: 0 }, { skip: !authenticated });

  if (!authenticated) {
    return null; // an overlay is displayed as part of the route configuration
  }

  if (facilityIsUninitialized || facilityIsLoading || !facilityIsSuccess || storeIsUninitialized || storeIsLoading || !storeIsSuccess) {
    return (
      <AgGridReact
        overlayLoadingTemplate={"Loading Facilities & Stores..."}
      />
    );
  }

  if (facilityIsError) {
    return (
      <AgGridReact
        loadingOverlayComponent={LoadError}
        loadingOverlayComponentParams={{
          message: "Failed to retrieve the list of Facilities",
          retry: facilityRefetch,
        }}
      />
    );
  }

  if (storeIsError) {
    return (
      <AgGridReact
        loadingOverlayComponent={LoadError}
        loadingOverlayComponentParams={{
          message: "Failed to retrieve the list of Stores",
          retry: storeRefetch,
        }}
      />
    );
  }

  return (
    <ErrorBoundary fallback={ErrorBoundaryFallback}>
      <BatchTableAgGrid {...props} facilities={facilityData} stores={storeData} />
      <BatchDetails {...props} />
    </ErrorBoundary>
  );
};

function ActionsColumnCellRenderer(setActiveBatchId: ((batchId: string | null | undefined) => void) | undefined): any {
  return function ActionsColumnCellRendererInternal(params: ICellRendererParams) {
    return (
      <>
        <Button size="small" onClick={() => setActiveBatchId?.(params.data.id)} key="actions-batch-details">
          Details
        </Button>
      </>
    );
  };
}
